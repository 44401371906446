* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

button {
  color: #000;
}

.wrapper {
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}

.mini-wrapper {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: "Cinzel", serif;
  overflow-x: hidden;
  scroll-padding-top: 10rem;
}

body {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(321deg, rgb(215, 215, 215) 14%, rgb(82, 82, 82) 99%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

h2 {
  margin-bottom: 3rem;
  padding: 5rem 0 2rem 0;
  font-family: "Cinzel", serif;
  font-size: 3rem;
  text-align: center;
  color: rgb(255, 255, 255);
}

.welcome__wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
  height: 100vh;
}
.welcome__img {
  display: none;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("/dist/img/field_small.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}
.welcome__shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.welcome__arrow {
  position: absolute;
  bottom: 10%;
  left: 50%;
  font-size: 3rem;
  color: rgb(250, 250, 250);
  z-index: 10;
  animation: welcomearrow 2s linear infinite forwards;
  transform-origin: left;
  cursor: pointer;
}
.welcome__arrow--circle {
  transform: translateX(-50%);
}
.welcome__company {
  position: absolute;
  font-family: "Cinzel", serif;
  top: 10%;
  left: 10%;
  font-size: 2rem;
  color: rgb(250, 250, 250);
}
.welcome__quote {
  position: absolute;
  top: 30%;
  right: 10%;
  width: 50%;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: rgb(250, 250, 250);
}

.navmobile {
  position: relative;
  overflow: hidden;
  height: 7rem;
}
.navmobile__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100%;
  background: linear-gradient(321deg, rgb(215, 215, 215) 14%, rgb(82, 82, 82) 99%);
  z-index: 100;
  transform: translateX(100%);
  transition: transform 1s;
}
.navmobile__list--exit {
  position: absolute;
  padding: 1rem;
  top: 5rem;
  right: 5rem;
  font-size: 3rem;
  color: rgb(255, 255, 255);
  background: none;
  border: none;
  cursor: pointer;
}
.navmobile__list--active {
  transform: translateX(0%);
}
.navmobile__link {
  width: 100%;
  padding: 3rem;
  font-size: 2.5rem;
  text-decoration: none;
  text-align: center;
  color: rgb(255, 255, 255);
  z-index: 120;
  transition: background-color 0.5s;
}
.navmobile__link:hover {
  background-color: rgb(36, 36, 36);
}
.navmobile__link--animation {
  animation: menuItemsAnim 1s both;
}
.navmobile__company {
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  font-size: 2rem;
  font-family: "Cinzel", serif;
  color: rgb(255, 255, 255);
}
.navmobile__btnbox {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.navmobile__button {
  position: fixed;
  top: 1.5rem;
  right: 3rem;
  padding: 0.5rem;
  background-color: rgb(36, 36, 36);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 3px;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  z-index: 50;
}

.navdesktop {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 10rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(274deg, rgb(148, 145, 145) 15%, rgb(82, 82, 82) 100%);
  z-index: 20;
  opacity: 1;
}
.navdesktop__wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.navdesktop__company {
  width: 15%;
  margin-left: 1rem;
  font-family: "Cinzel", serif;
  color: rgb(255, 255, 255);
}
.navdesktop__link {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.navdesktop__link--active {
  color: black;
  text-shadow: 1px 1px rgb(39, 37, 37);
}
.navdesktop__list {
  position: relative;
  width: 80%;
  text-align: right;
  margin-right: 0.5rem;
}
.navdesktop__list button:first-child {
  display: none;
}
.navdesktop__list--slice {
  position: relative;
  height: 10rem;
  margin-right: 0.5rem;
  padding: 1rem 0.8rem;
  font-family: "Cinzel", serif;
  font-size: 1.6rem;
  border: none;
  background: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 0;
}
.navdesktop__list--slice a {
  padding: 5rem 0 5rem 0;
}
.navdesktop__list--slice::after, .navdesktop__list--slice::before {
  display: block;
  position: absolute;
  content: "";
  transition: all 0.5s;
  z-index: -1;
}
.navdesktop__list--slice::before, .navdesktop__list--slice::after {
  width: 100%;
  height: 100%;
  background-color: rgb(36, 36, 36);
}
.navdesktop__list--slice::before {
  top: -50%;
  left: -100%;
}
.navdesktop__list--slice::after {
  bottom: -50%;
  right: -100%;
}
.navdesktop__list--slice:hover::before {
  left: 0;
}
.navdesktop__list--slice:hover::after {
  right: 0;
}

header {
  position: relative;
}
header .header__img {
  position: relative;
  height: 35rem;
  background-image: url("/dist/img/fabric_small.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
header .header__shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
header .header__text {
  position: relative;
  height: 100%;
}
header .header__empty {
  display: none;
  height: 10rem;
}
header h1 {
  position: absolute;
  bottom: 10%;
  left: 10%;
  right: 10%;
  font-size: 2.2rem;
  font-family: "Cinzel", serif;
  font-weight: 200;
  color: rgb(255, 255, 255);
  text-align: center;
}

.aboutus {
  position: relative;
}
.aboutus__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.aboutus__box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  width: 60vw;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  margin-bottom: 2rem;
}
.aboutus__title {
  padding: 1rem;
  margin-bottom: 1rem;
  height: 5rem;
  color: rgb(26, 22, 18);
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
.aboutus__line {
  height: 0.2rem;
  width: 75%;
  border-bottom: 1px solid rgb(26, 22, 18);
}
.aboutus__text {
  width: 90%;
  padding: 1rem;
  font-size: 1.2rem;
  color: rgb(26, 22, 18);
  text-align: center;
}

.linen {
  position: relative;
  padding-top: 2rem;
}
.linen h2 {
  margin-bottom: 1rem;
  padding: 0;
}
.linen h3 {
  margin-bottom: 2rem;
  padding: 0 0 1rem 0;
  font-family: "Cinzel", serif;
  font-size: 2rem;
  text-align: center;
  color: rgb(255, 255, 255);
}
.linen__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.linen__card {
  position: relative;
  margin: 2rem 3rem;
  width: 100%;
  max-width: 30rem;
  height: 40rem;
  perspective: 50rem;
  border-radius: 5rem;
}
.linen__card h3 {
  margin-bottom: 1rem;
  padding: 1rem;
  color: rgb(26, 22, 18);
}
.linen__questionmark {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 3rem 4rem;
  border: 2px solid black;
  border-radius: 50%;
  background-color: rgba(240, 248, 255, 0.568);
  font-size: 8rem;
  font-weight: bold;
  transform: translate(-50%, -50%);
  transition: transform 0.8s, opacity 0.8s, display 0.8s;
}
.linen__img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 5rem;
  transition: transform 0.8s, opacity 0.8s;
}
.linen__img--one {
  background-image: url("/dist/img/one.jpg");
}
.linen__img--two {
  background-image: url("/dist/img/two.jpg");
}
.linen__img--three {
  background-image: url("/dist/img/three.jpg");
}
.linen__img--four {
  background-image: url("/dist/img/four.jpg");
}
.linen__text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 3rem;
  background-color: rgb(255, 255, 255);
  border-radius: 5rem;
  font-size: 1.4rem;
  text-align: center;
  opacity: 0;
  transition: transform 0.8s, opacity 0.8s;
  transform: rotateX(-180deg);
}
.linen__answer {
  display: none;
  position: absolute;
  width: 40rem;
  top: 50%;
  left: 50%;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  transition: transform 0.8s, opacity 0.8s;
  transform: translate(-50%, -50%) rotate(-50deg);
}
.linen__card:hover .linen__text {
  transform: rotateX(0);
  opacity: 1;
}
.linen__card:hover .linen__img {
  transform: rotateX(180deg);
  opacity: 0;
}
.linen__card:hover .linen__questionmark {
  transform: rotateX(180deg translate(-50%, -50%));
  transform-origin: left;
  opacity: 0;
}
.linen__card:hover .linen__answer {
  transform: rotateX(180deg translate(-50%, -50%));
  transform-origin: center;
  opacity: 0;
}
.linen__animation {
  display: inline;
  position: absolute;
  top: -5%;
  left: 10%;
  width: 25rem;
  opacity: 0.1;
  z-index: -5;
  animation: rotate-right 50s infinite linear;
}

.offer {
  position: relative;
  color: rgb(255, 255, 255);
}
.offer h2 {
  padding: 2rem 0 0rem 0;
}
.offer__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.offer__img img {
  width: 80vw;
  margin-top: 1rem;
  padding: 2rem;
  border-radius: 3rem;
}
.offer__list {
  text-align: center;
}
.offer__list h3 {
  margin: 0 1rem 0 1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
}
.offer__list li {
  margin: 0 1rem 0 1rem;
  padding: 0.5rem;
  font-size: 1.4rem;
  list-style: none;
}
.offer__animation {
  display: inline;
  position: absolute;
  top: -10%;
  right: 10%;
  width: 30rem;
  opacity: 0.05;
  z-index: -5;
  animation: rotate-left 70s infinite linear;
}

.contact {
  position: relative;
}
.contact h2 {
  padding: 2rem 0 1rem 0;
}
.contact__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact__text {
  position: relative;
  padding: 0 4rem 4rem 4rem;
  font-size: 1.7rem;
  color: rgb(255, 255, 255);
}
.contact__text--free {
  color: rgb(248, 54, 64);
}
.contact__phone {
  padding-bottom: 1rem;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}
.contact form {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Cinzel", serif;
}
.contact form label {
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.contact form input {
  width: 80vw;
  padding: 0.2rem;
  font-family: "Cinzel", serif;
  border: 1px solid black;
  border-radius: 0.5rem;
}
.contact form textarea {
  max-width: 80vw;
  font-family: "Cinzel", serif;
  padding: 0.2rem;
  min-height: 20rem;
  border: 1px solid black;
  border-radius: 0.5rem;
}
.contact form .contact__confirm {
  width: 15rem;
  height: 7rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding: 2rem 4rem;
  font-family: "Cinzel", serif;
  font-weight: bold;
  background-color: #f0f0f0;
  color: black;
  border: 1px solid black;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
}
.contact form .contact__confirm:hover {
  color: white;
  background-color: black;
}
.contact__animation {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 40%;
  opacity: 0.1;
  transform: translate(-50%, -50%);
  z-index: -5;
}

footer {
  height: 20rem;
  background: linear-gradient(321deg, rgb(180, 180, 180) 14%, rgb(48, 48, 48) 99%);
  margin-top: auto;
}
footer .footer__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .footer__date {
  position: absolute;
  right: 50%;
  bottom: 1rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  text-align: center;
  transform: translateX(50%);
}
footer .footer__address {
  margin-left: auto;
  margin-right: 5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}
footer .footer__address p {
  color: white;
  font-size: 1.2rem;
}
footer .footer__address p:nth-child(1) {
  font-size: 1.5rem;
  font-weight: bold;
}
footer .footer__address hr {
  color: white;
}

@media (-webkit-device-pixel-ratio: 3) {
  .contact form .contact__confirm {
    height: 5rem;
  }
}
@media (min-width: 320px) {
  .welcome__img {
    background-image: url("/dist/img/field_medium.jpg");
  }
  header .header__img {
    background-image: url("/dist/img/fabric_medium.jpg");
  }
  header h1 {
    font-size: 2.4rem;
  }
}
@media (min-width: 576px) {
  h2 {
    font-size: 3.5rem;
  }
  .welcome__company {
    font-size: 3rem;
  }
  .welcome__quote {
    line-height: 2rem;
  }
  .welcome__arrow {
    font-size: 4rem;
  }
  .navmobile {
    display: none;
  }
  .navdesktop {
    display: flex;
  }
  .navdesktop__company {
    font-size: 2.5rem;
  }
  header .header__empty {
    display: block;
  }
  header h1 {
    bottom: 15%;
    font-size: 3.5rem;
  }
  .aboutus__title {
    width: 50vw;
    font-size: 2rem;
  }
  .aboutus__text {
    margin-top: 2rem;
    font-size: 1.5rem;
  }
  .linen__animation {
    top: 2%;
    left: 10%;
  }
  .offer__img img {
    max-width: 50rem;
  }
  .offer__list h3 {
    font-size: 2.4rem;
    padding-bottom: 2rem;
  }
  .offer__list li {
    text-align: start;
    font-size: 1.8rem;
    list-style: disc;
  }
  .offer__animation {
    top: 5%;
    right: 10%;
  }
  .contact__animation {
    width: 30%;
  }
}
@media (min-width: 768px) {
  h2 {
    font-size: 4rem;
  }
  .welcome__company {
    font-size: 4rem;
  }
  .welcome__quote {
    width: 40%;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .navdesktop__company {
    margin-left: 2.5rem;
    font-size: 2.8rem;
  }
  .navdesktop__list {
    margin-right: 3rem;
  }
  .navdesktop__list--slice {
    padding: 1rem 1.9rem;
    font-size: 1.8rem;
    font-weight: bold;
  }
  header .header__img {
    height: 35rem;
  }
  header h1 {
    font-size: 3.5rem;
  }
  .aboutus__container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .aboutus__box {
    width: 40rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
  }
  .linen h3 {
    font-size: 2.4rem;
  }
  .offer__img img {
    max-width: 60rem;
  }
  .offer__list h3 {
    font-size: 2.6rem;
  }
  .offer__list li {
    font-size: 2rem;
  }
  .offer__animation {
    top: 12%;
  }
  .contact__animation {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .welcome__img {
    background-image: url("/dist/img/field_big.jpg");
  }
  .welcome__quote {
    width: 35%;
  }
  .welcome__arrow {
    font-size: 7rem;
  }
  .navdesktop__company {
    margin-left: 4rem;
    font-size: 3rem;
  }
  .navdesktop__link--home {
    display: inline;
  }
  .navdesktop__list button:first-child {
    display: inline;
  }
  header .header__img {
    height: 40rem;
    background-image: url("/dist/img/fabric_medium.jpg");
  }
  header h1 {
    left: auto;
    right: 5%;
    font-size: 5rem;
  }
  .linen__container--wrapper {
    margin: 0 auto;
    margin-bottom: 3rem;
    max-width: 75vw;
    width: 100%;
  }
  .linen__card {
    max-width: 30rem;
    height: 40rem;
  }
  .linen__animation {
    width: 35rem;
  }
  .offer__container {
    flex-direction: row-reverse;
  }
  .offer__img img {
    margin-right: 3rem;
    width: 45rem;
  }
  .offer__animation {
    top: 70%;
  }
  .contact__container {
    flex-direction: row;
  }
  .contact__text {
    font-size: 2rem;
    padding-bottom: 10rem;
    text-align: center;
  }
  .contact form {
    padding: 0 5rem 5rem 1rem;
  }
  .contact form input {
    width: 45rem;
  }
  .contact__animation {
    width: 60%;
  }
  footer {
    height: 25rem;
  }
  footer .footer__address {
    line-height: 2.5rem;
  }
  footer .footer__address p {
    font-size: 1.5rem;
  }
  footer .footer__address p:nth-child(1) {
    font-size: 1.8rem;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 4.5rem;
  }
  .linen__card {
    max-width: 36rem;
    height: 48rem;
  }
  .offer__img img {
    margin-right: 5rem;
    width: 60rem;
  }
  .contact__text {
    font-size: 2.5rem;
  }
}
@media (min-width: 1500px) {
  .linen__container--wrapper {
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
  }
  .linen__card {
    max-width: 30rem;
    height: 40rem;
  }
}
@media (min-width: 1800px) {
  header .header__img {
    background-image: url("/dist/img/fabric_big.jpg");
  }
}
@keyframes welcomearrow {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes menuItemsAnim {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes rotate-right {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate-left {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}